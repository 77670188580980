import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import "queries/defaultTemplateQuery"
import FlexibleContent from "components/FlexibleContent/FlexibleContent"

const NotFound404 = (props) => {
    const data = props.data.wpPage;
    const acfBlocks = data.template.acfFlexibleContent.blocks
    const title = data.title
    return (
        <Layout title={title} description={data.seo.metaDesc}>
            <FlexibleContent blocks={acfBlocks} />
        </Layout>
    )
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                wpPage(slug: { eq: "404-page-not-found" }) {
                    id
                    title
                    seo {
                        title
                        metaDesc
                    }
                    template {
                        ... on WpDefaultTemplate {
                            ...defaultTemplateQuery
                        }
                    }
                }
            }
        `}
        render={data => {
            if (data.wpPage) {
                return (
                    <NotFound404 data={data} />
                )
            } else return null
        }}
    />
)